.Website {

    @extend .container-fluid;

    &.spaced {

        > .row + .row {
            margin-top: 64px;
        }
    }
}

.simple-button {

    display: inline-block;
    padding: 8px 12px;
    background-color: theme-color("accent");
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 6px;

    transition: transform 0.15s ease-out;

    &.outline {
        background-color: transparent;
        color: theme-color("accent");
        border: 1px solid theme-color("accent");
    }

    &.light {
        background-color: #fff;
        color: theme-color("accent");

        &.outline {
            background-color: transparent;
            color: #fff;
            border: 1px solid #fff;
        }
    }

    &:hover {
        transform: translate(0px, -2px);
        text-decoration: none;

        &:not(.light) {
            color: #fff;
        }
    }
}