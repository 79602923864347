.device {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    
    text-align: center;
    
    @include media-breakpoint-only(xs) {

        max-width: 100%;

        &.right-xs {
                margin-left: 10%;
                margin-right: 0;
        }

        &.left-xs {
            margin-left: 0;
            margin-right: 10%;
        } 
    }

    @include media-breakpoint-only(sm) {
        &.right-sm {
                margin-left: 10%;
                margin-right: 0;
        }
        
        &.left-sm {
            margin-left: 0;
            margin-right: 10%;
        } 
    }

    @include media-breakpoint-only(md) {
        &.right-md {
                margin-left: 10%;
                margin-right: 0;
        }
        
        &.left-md {
            margin-left: 0;
            margin-right: 10%;
        } 
    }

    @include media-breakpoint-up(lg) {
        &.right-lg {
                margin-left: 10%;
                margin-right: 0;
        }
        
        &.left-lg {
            margin-left: 0;
            margin-right: 10%;
        } 
    }
    
    > .img {
        max-width: 100%; 
        margin-bottom: 0;
        
        img {
            max-width: 100%; 
        }
        
        &.shadow {

            position: relative;
            z-index: 2;
            
            img {
                z-index: 2; 
            }
            
            &:after {

                content: "";
                position: absolute;
                z-index: 1;  /* to be below the parent element */
                left: 10%;
                bottom: 0;
                width: 80%;
                height: 0.5%;
                box-shadow: 0 15px 30px 20px rgba(black, .5);
                border-radius: 100%;
                
            }
        }
    }


    .caption {

        p {
            font-size:1em;
            color: $gray-600;
            margin-left: auto; 
            margin-right: auto;
            width: 80%;
            margin-top: 5%;
            text-align:left;
        }
    }
}

.device-object {

    display: inline-block;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
    > img, .img {
        position: absolute;
    }

    > div.loading {
        position:absolute;
        text-align:center;
        >i {
            color:white;
            position:relative;
            top:50%;
        }
    }

    .img {
        @extend .gradient-primary;
    }

    &.shadow {

        z-index: 10;
        margin-bottom: 60px;
        
        img, .img {
            z-index: 10; 
        }
        
        &:after {

            content: "";
            position: absolute;
            z-index: -1;  /* to be below the parent element */
            left: 10%;
            bottom: 0;
            width: 80%;
            height: 0.1%;
            box-shadow: 0 30px 20px 4px rgba(black, .5);
            border-radius: 100%;
            
        }
    }
    
    &.iphone {

        background-image: url('/site/S/img/devices/iphone.png');
        
        width: 395px;
        height: 810px;
        
        > img, .img, div.loading {
            width: 349px;
            height: 617px;
            top: 95px;
            left: 22px;
        }
        
        &.small {

            background-image: url('/site/S/img/devices/iphone-small.png');
            
            width: 200px;
            height: 410px;
            
            > img, .img, div.loading {

                width: 177px;
                height: 313px;
                top: 48px;
                left: 11px;
            }
        }

        &.medium {
            
            width: 280px;
            height: 574px;
            
            > img, .img, div.loading {

                width: 247px;
                height: 438px;
                top: 67px;
                left: 16px;
            }
        }
    }

    &.iphone-wireframe {

        background-image: url('/site/S/img/devices/iphone_plus_wireframe.png');
        
        width: 459px;
        height: 920px;
        
        > img, .img, div.loading {

            width: 431px;
            height: 737px;
            top: 79px;
            left: 14px;
        }
        
        &.small {

            background-image: url('/site/S/img/devices/iphone_plus_wireframe-small.png');
            
            width: 230px;
            height: 460px;
            
            > img, .img, div.loading {

                width: 216px;
                height: 369px;
                top: 39px;
                left: 7px;
            }
        }
    }

    &.ipad {

        background-image: url('/site/S/img/devices/ipad.png');
        
        height: 800px;
        width: 567px;
        
        > img, .img, div.loading {

            height: 660px;
            width: 498px;
            top: 33px;
            left: 70px;
        }
        
        &.small {

            background-image: url('/site/S/img/devices/ipad-small.png');
            
            height: 480px;
            width: 340px;
            
            > img, .img, div.loading {

                height: 396px;
                width: 298px;
                top: 20px;
                left: 42px;
            }
        }

        &.medium {
            
            height: 560px;
            width: 397px;
            
            > img, .img, div.loading {

                height: 463px;
                width: 349px;
                top: 23px;
                left: 49px;
            }
        }
    }

    &.ipad-landscape {

        background-image: url('/site/S/img/devices/ipad-landscape.png');

        width: 800px;
        height: 567px;

        > img, .img, div.loading {

            width: 660px;
            height: 498px;
            top: 33px;
            left: 70px;
        }

        &.small {

            background-image: url('/site/S/img/devices/ipad-landscape-small.png');

            width: 480px;
            height: 340px;

            > img, .img, div.loading {

                width: 396px;
                height: 298px;
                top: 20px;
                left: 42px;
            }
        }

        &.medium {

            width: 560px;
            height: 397px;

            > img, .img, div.loading {

                width: 463px;
                height: 349px;
                top: 23px;
                left: 49px;
            }
        }
    }

    &.smartphone {

        background-image: url('/site/S/img/devices/smartphone.png');
        
        width: 440px;
        height: 810px;
        
        > img, .img, div.loading {
            width: 363px;
            height: 647px;
            top: 78px;
            left: 38px;
        }
        
        &.small {

            background-image: url('/site/S/img/devices/smartphone-small.png');
            
            width: 223px;
            height: 410px;
            
            > img, .img, div.loading {

                width: 184px;
                height: 327px;
                top: 40px;
                left: 19px;
            }
        }

        &.medium {
            
            width: 312px;
            height: 574px;
            
            > img, .img, div.loading {

                width: 257px;
                height: 458px;
                top: 56px;
                left: 27px;
            }
        }
    }


    &.tablet {

        background-image: url('/site/S/img/devices/tablet.png');
        
        height: 800px;
        width: 575px;
        
        > img, .img, div.loading {

            height: 639px;
            width: 400px;
            top: 87px;
            left: 82px;
        }
        
        &.small {

            background-image: url('/site/S/img/devices/tablet-small.png');

            height: 480px;
            width: 345px;
            
            > img, .img, div.loading {

                height: 384px;
                width: 240px;
                top: 52px;
                left: 49px;
            }
        }

        &.medium {

            height: 560px;
            width: 403px;
            
            > img, .img, div.loading {

                height: 448px;
                width: 280px;
                top: 61px;
                left: 57px;
            }
        }
    }

    &.tablet-landscape {

        background-image: url('/site/S/img/devices/tablet-landscape.png');

        width: 800px;
        height: 575px;

        > img, .img, div.loading {

            width: 639px;
            height: 400px;
            top: 87px;
            left: 82px;
        }

        &.small {

            background-image: url('/site/S/img/devices/tablet-landscape-small.png');

            width: 480px;
            height: 345px;

            > img, .img, div.loading {

                width: 384px;
                height: 240px;
                top: 52px;
                left: 49px;
            }
        }

        &.medium {

            width: 560px;
            height: 403px;

            > img, .img, div.loading {

                width: 448px;
                height: 280px;
                top: 61px;
                left: 57px;
            }
        }
    }

    &.applewatch {

        background-image: url('/site/S/img/devices/apple-watch.png');
        
        width: 280px;
        height: 411px;
        
        > img, .img, div.loading {

            width: 185px;
            height: 196px;
            top: 110px;
            left: 41px;
        }
    }
}