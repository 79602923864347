#TopBar {
    $topbar-height: 64px;

    @extend .container-fluid;
    @extend .gradient-primary;

    height: $topbar-height;
    overflow: hidden;

    &:not(.show-spy) {
        ul.spy-menu {
            display: none;
        }
    }

    &.show-spy {
        .logo {
            display: none;
        }
    }

    &:not(.fixed) {

        @include media-breakpoint-up(sm) {
            margin-bottom: 22px;
        }
    }

    &.fixed {
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
        z-index: 10;
    }

    .row {
        height: $topbar-height;
        @extend .justify-content-around;
    }

    .logo {

        @extend .col-4;
        @extend .col-md-6;
        @include media-breakpoint-only(xs) {
            display: none;
        }

        line-height : $topbar-height;
        vertical-align: middle;

        height: $topbar-height;

        h3 {
            font-weight: 300;
            display: inline;
            vertical-align: middle;
            margin-left: 8px;
            color:white;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .right {
        @extend .col-6;
        @extend .col-md-6;

        text-align: right;
        line-height : $topbar-height;
        vertical-align: middle;
        color:white;

        a.StoreButton {

            margin-top:10px;
            
            &:not(.full) {
                @include media-breakpoint-only(xs) {
                    .image { margin-right: 0; }
                    span { display:none; }
                }
            }
        }
    }

    ul.menu {

        text-align: center;

        @include media-breakpoint-only(xs) {
            &.pull-right {
                padding: 0;
                width: 100%;
                white-space: nowrap;
                overflow-x:auto;
            }
        }

        li {
            display: inline-block;
            line-height: $topbar-height;
            vertical-align: middle;
            margin: 0 4px;

            @include media-breakpoint-only(xs) {
                margin:0;
            }

            list-style: none;
            color: white;

            a {
                color: rgba(white, 0.9);
                padding: 4px 8px;
                text-decoration: none;
                outline:0;
                font-weight: 100;

                &:hover {
                    background-color: rgba(white, 0.7);
                    border-radius: 4px;
                    color: theme-color("primary");
                    box-shadow: 0px 1px 4px rgba(black, 0.06);
                }
            }

            strong {
                font-weight: 500;
                padding: 4px 8px;
                background-color: rgba(white, 0.95);
                border-radius: 4px;
                color: theme-color("primary");
                box-shadow: 0px 1px 4px rgba(black, 0.06);

            }

        }
    }

    ul.spy-menu {

        @extend .col-6;
        @extend .col-md-6;
        padding-left: 0;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        li {

            display: inline-block;
            margin: 0 8px;
            line-height: $topbar-height;
            vertical-align: middle;
            list-style: none;
            color: white;

            @include media-breakpoint-only(xs) {
                margin:0;
            }

            a {
                color: rgba(white, 0.9);
                padding: 0;
                padding-bottom: 4px;
                text-decoration: none;
                outline:0;
                font-weight: 100;
                font-size: 0.9rem;
                height: $topbar-height - 12px;

                &:hover {
                    color: #fff;
                }

                &.active {
                    border-bottom: 2px solid #fff;
                    color: #fff;
                }
            }
        }


    }
}