#Footer {

    @extend .gradient-primary, .gradient-gray;

    padding-top: 86px;
    position: relative;

    #scrollTopButton {

        width: 64px;
        height: 64px;
        background-color: rgba(0,0,0,0.3);
        color: #fff;
        font-size: 3rem;
        text-align: center;
        line-height: 54px;
        border-radius: 32px;
        vertical-align: middle;
        position: absolute;
        top: 22px;
        left: 50%;
        margin-left: -32px;
        cursor: pointer;
    }

    ul.links {
        
        text-align: center;
        padding-left: 0;
        margin-top: 32px;

        li {

            list-style: none;
            color: #fff;
            margin: 18px 0px;
            font-size: 0.9rem;

            a {

                color: #fff;
                border-radius: 4px;
                padding: 4px 8px;
                font-weight: 100;
                text-decoration: none;

                &:hover {
                    color: $gray-800;
                    background-color: #fff;
                }
            }

            strong {

                font-weight: 100;
                color: theme-color("accent");
                background-color: #fff;
                border-radius: 4px;
                padding: 4px 8px;
            }
        }
    }

    .copyright {
        text-align: center;
        color: #fff;
        opacity: 0.9;
        font-size: 0.7rem;
        font-weight: 100;
        margin-top: 32px;
        margin-bottom: 22px;
    }
}