
a.StoreButton {
    line-height:normal;
    display: inline-block;
    background-color: theme-color("accent");
    border-radius: 6px;
    color: white;
    padding: 12px 20px;
    text-align: left;
    transition: transform 0.15s ease-out;

    &:hover {
        transform: translate(0px, -2px);
    }

    &.light {
        background-color: white;
        color: theme-color("accent");
    }

    .image {
        float: left;
        margin-right: 20px;
        line-height: 43px;
        width:20px;
        vertical-align: middle;
    }

    > span {
        display:block;
        float: left;

        strong {
            font-size:120%;
        }

        > * {
            display:block;
            margin: 0;
        }

        > :not(:first-child) {
            margin-top: 2px;
        }
    }

    &.small {
        border-radius: 4px;
        padding: 6px 12px;

        .image {
            margin-right: 10px;
            line-height: 33px;
            font-size:22px;
            width:16px;
        }

        > span {
            > :not(:first-child) {
                margin-top:0;
            }
        }
    }

}

a.download {
    display: inline-block;
    background-color: theme-color("accent");
    -webkit-border-radius: 6px; 
     border-radius: 6px;  
    color: white;
    padding: 10px 20px;
    text-align: left;
    
    &.light {
        background-color: white;
        color: $gray-700; 
    }

    .image {
        float: left;
        margin-right: 20px;
        line-height: 43px;
        vertical-align: middle;
    }

    > div {
        float: left;
        
        > * {
            margin: 0px;
        } 

        > :not(:first-child) {
            margin-top: 5px;
        }
    }
}

a.download + a.download {
    margin-left: 10px; 
}

.download-for-more {
    @extend .gradient-primary;
    color: white;
    margin: 15px;
    padding: 15px;
    border-radius: 4px;

    > i {
        margin-bottom: 15px;
        width: auto;
    }

    > .left {
        margin-bottom: 15px;

        p {
            font-size: 16px;
        }
    }
}


a.DiscoverButton {
    display:inline-block;
    min-width:320px;
    max-width:400px;
    background-color: white;
    border-radius: 6px;
    color: theme-color("accent");
    height:80px;
    padding: 10px 20px;
    text-align: left;
    transition: transform 0.15s ease-out;

    > i {
        float: right;
        font-size:28px;
        margin-left: 20px;
        line-height: 60px;
        vertical-align: middle;
    }

    > span {
        display:block;
        margin:2px 0;
    }

    > strong {
        margin:2px 0;
        font-size: 120%;
        font-weight: 500;
        text-transform: uppercase;
    }

    &:hover {
        transform: translate(0px, -2px);
    }

}

a.DownloadButton {
    display: inline-block;
    background-color: theme-color("accent");
    border-radius: 6px;
    color: white;
    padding: 12px 20px;
    text-align: left;
    transition: transform 0.15s ease-out;

    &:hover {
        transform: translate(0px, -2px);
    }

    &.inline {
        display: inline-block;
    }

    &.light {
        background-color: white;
        color: $gray-700; 
    }

    .image {
        float: left;
        margin-right: 20px;
        line-height: 43px;
        vertical-align: middle;
    }

    > span {
        display:block;
        float: left;

        strong {
            font-size:120%;
        }

        > * {
            display:block;
            margin: 0;
        } 

        > :not(:first-child) {
            margin-top: 2px;
        }
    }

    &.small {
        border-radius: 4px;
        padding: 6px 12px;

        .image {
            margin-right: 10px;
            line-height: 33px;
            font-size:22px;
        }

        > span {
            > :not(:first-child) {
                margin-top:0;
            }
        }
    }
}
