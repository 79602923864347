.Section {
    
    .pro {

        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 15px;
        padding-bottom: 54px;
        margin-bottom: 64px;
        box-shadow: 0px 6px 22px rgba(0,0,0,0.2);
        text-align: right;
        overflow: hidden;
        position: relative;

        @include media-breakpoint-up(sm) {
            border-radius: 4px;
        }

        &.small {
            @extend .col-lg-7;
            text-align: center;
            padding-bottom: 1rem;
            padding-top: 1rem;
        }

        &:not(.small) {
            @extend .col-12;
        }

        &:after, &:before {
            content: '';
            position: absolute;
            background-color: #000;
            opacity: 0.3;
            width: 100%;
            height: 400%;
            top: 100%;
            margin-top: -50%;
            right: -60%;
            transform: skew(29deg);
        }

        &:before {
            width: 110%;
        }

        > div {
            z-index: 2;
            position: relative;
        }

        h2 {
            color: #fff;
            text-shadow: 0px 3px 22px rgb(0,0,0);
            margin-bottom: 0;

            &:before {
                background-color: transparent;
            }
        }

        p {
            color: #fff;
            text-shadow: 0px 3px 22px rgb(0,0,0);
        }

        // i {
        //     color: #fff;
        // }

        &.simple {
            @extend .gradient-primary;
            h2, p {
                text-shadow: none;
            }
            
            &:after, &:before {
                opacity: 0.05;
            }
        }
    }
}