$background-color: #fff;

$theme-colors: (
  "primary":    #BE4B39,
  "secondary":  #E85943,
  "success":    #5cb85c,
  "info":       #5bc0de,
  "warning":    #f0ad4e,
  "danger":     #d9534f,
  "accent":     #DC0007
);

//Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../../node_modules/bootstrap/scss/variables";

// Reset and dependencies
@import "../../../node_modules/bootstrap/scss/print";

// Core CSS
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/code";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/list-group";

// Components
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/modal";

// Utility classes
@import "../../../node_modules/bootstrap/scss/utilities";