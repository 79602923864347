.Section {
    
    .slogan {

        @extend .col-md-8;

        position: relative;

        p.up {
            text-transform: uppercase;
        }

        &:before {

            @extend .triangles-pattern;

            content: '';
            position: absolute;
            bottom: -22px;
            left: -22px;
            width: 30%;
            height: 100%;
            z-index: -1;
        }

        &.right {

            text-align: right;

            &:before {
                left: auto;
                right: -22px;
            }

            h2 {
                &:before {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}