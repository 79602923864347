.CitiesMap {

    position: relative;
    margin: 0px auto;

    @include media-breakpoint-only(xs) {

        margin-bottom: 64px;

        .pin {

            background-color: #fff;
            box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.13);
            border-radius: 4px;
            padding: 12px 16px;
            text-decoration: none;
            display: inline-block;

            h4 {

                text-transform: uppercase;
                font-size: 1rem;
                font-weight: 800;
                color: $gray-800;
                margin-bottom: 0;
                transition: color 0.4s ease-out;
            }

            p {

                color: theme-color("accent");
                font-size: 0.8rem;
                margin-bottom: 0;
                transition: color 0.4s ease-out;
            }

            &:hover {

                background-color: theme-color("accent");

                &:after {
                    border-top: 12px solid theme-color("accent");
                }

                h4 {
                    color: #fff;
                }

                p {
                    color: #fff;
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {

        margin: 64px auto;

        background-image: url('/site/S/img/france.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 494px;
        height: 480px;


        .pin {

            position: absolute;
            display: block;
            background-color: #fff;
            box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.13);
            border-radius: 4px;
            padding: 12px 16px;
            text-decoration: none;
            transform-origin: center bottom;
            transition: transform 0.6s ease-out, background-color 0.4s ease-out;

            h4 {

                text-transform: uppercase;
                font-size: 1rem;
                font-weight: 800;
                color: $gray-800;
                margin-bottom: 0;
                transition: color 0.4s ease-out;
            }

            p {

                color: theme-color("accent");
                font-size: 0.8rem;
                margin-bottom: 0;
                transition: color 0.4s ease-out;
            }

            &:after {

                content: '';
                position: absolute;
                height: 0;
                width: 0;
                left: 50%;
                top: 100%;
                margin-left: -6px;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 12px solid #fff;
                transition: border-top 0.4s ease-out;
            }

            &:hover {

                background-color: theme-color("accent");
                transform: scale(1.06);

                &:after {
                    border-top: 12px solid theme-color("accent");
                }

                h4 {
                    color: #fff;
                }

                p {
                    color: #fff;
                }
            }

            // Animation
            &.wow {

                transform: scale(0.9);

                &.animated {
                    transform: scale(1.0);
                }
            }

            &#sucy {

                top: 44px;
                left: 165px
            }

            &#frejus {

                top: 307px;
                left: 321px;
            }
        }
    }
}