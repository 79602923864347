.Section {

    .photo {

        @extend .d-flex;

        perspective: 1000px;

        &.large {
            @extend .col-lg-11;
        }

        &:not(.large) {
            @extend .col-lg-8;
        }

        h2 {
            font-size: 1.8rem;
        }

        h3 {
            font-size: 1.4rem;
            font-weight: 700;
        }

        p.lead {
            font-size: 1.0rem;
            font-weight: 400;
            color: $gray-700;
            margin-bottom: 2rem;
        }

        img {

            top: 0;
            height: 512px;
            border-radius: 20px;
            filter: drop-shadow(0px 28px 18px rgba(0, 0, 0, 0.14));

            @include media-breakpoint-down(sm) {
                height: 280px;
            }

            @include media-breakpoint-only(xs) {
                display: none;
            }
        }

        .content {
            margin-top: 64px;
            @include media-breakpoint-only(xs) {
                width: 100%;
            }
        }

        ul {

            list-style: none;
            color: $gray-600;
            padding: 0;

            li {

                position: relative;
                text-transform: uppercase;
                font-size: 12px;

                @include media-breakpoint-only(xs) {
                    display: inline-block;
                    background-color: theme-color("accent");
                    color: #fff;
                    padding: 4px 6px;
                    border-radius: 4px;
                    margin-left: 0.2rem;
                }

                @include media-breakpoint-up(sm) {

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        margin-top: -6px;
                        width: 12px;
                        height: 12px;
                        border-radius: 6px;
                        background-color: theme-color("accent");
                    }
                }
            }

            li + li {
                margin-top: 8px;
            }
        }

        &:not(.right) {

            @extend .justify-content-sm-end;

            img {
                left: 0;
                transform: rotateY(24deg) rotateZ(-2deg);
                transition: transform 0.4s ease-in-out;
                margin-right: 62px;

                @include media-breakpoint-down(sm) {
                    margin-right: 24px;
                    margin-left: -15px;
                }

                &:hover {
                    transform: rotateY(12deg) rotateZ(-2deg);
                }
            }
            @include media-breakpoint-up(sm) {
                ul {
                    li {
                        padding-left: 22px;
                        &:before {
                            left: 0;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-only(xs) {

            p {
                text-align: justify;
            }
        }


        &.right {

            @include media-breakpoint-up(sm) {

                img {
                    right: 0;
                    transform: rotateY(-24deg) rotateZ(2deg);
                    transition: transform 0.4s ease-in-out;
                    margin-left: 62px;

                    @include media-breakpoint-down(sm) {
                        margin-left: 24px;
                        margin-right: -15px;
                        transform: rotateY(-12deg) rotateZ(2deg);
                    }

                    &:hover {
                        transform: rotateY(-12deg) rotateZ(2deg);
                    }
                }

                &:not(.large) {

                    .content {
                        text-align: right;
                    }

                    h2 {

                        &:before {
                            left: inherit;
                            right: 0;
                        }
                    }
                }

                ul {
                    li {
                        padding-right: 22px;
                        &:before {
                            right: 0;
                        }
                    }
                }
            }
        }

        &.large { 

            &:not(.right) {
                img {
                    @include media-breakpoint-up(lg) {
                        margin-right: 128px;
                    }
                }
            }

            &.right {
                img {
                    @include media-breakpoint-up(lg) {
                        margin-left: 128px;
                    }
                }
            }

            .content { 
                margin-bottom: 64px;
            }
        }
    }
}