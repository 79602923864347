.Section {

    .prices {

        @extend .col-12, .d-flex, .justify-content-center, .align-items-center;

        .separator {

            @extend .w-100;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }

        > .card {

            max-width: 432px;
            border-radius: 12px;

            > .body {

                @extend .card-body;

                padding-bottom: 0;

                h3 {

                    @extend .card-title;

                    font-weight: 800;
                    margin-bottom: 0;
                }

                .list {

                    @extend .list-group, .list-group-flush;

                    margin-left: -20px;
                    margin-right: -20px;

                    li  {

                        @extend .list-group-item;

                        background-color: transparent;
                        border: none;
                        border-radius: 0;
                        border-top: 1px solid rgba(0,0,0,0.1);

                        h4 {
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        p {
                            font-size: 0.8rem;
                            margin-bottom: 0;

                            &.value {
                                font-weight: 800;
                                font-size: 1.1rem;
                                display: inline-block;
                                padding: 4px 8px;
                                background-color: $gray-800;
                                color: #fff;
                                margin-top: 12px;
                                border-radius: 4px;

                                &.premium {
                                    background-color: #fff;
                                    color: theme-color("success");
                                }
                            }
                        }

                        &:first-child {
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                        }
                    }
                }
            }

            &.premium {

                @extend .gradient-primary;

                border: none;
                color: #fff;
                box-shadow: 0px 6px 54px rgba(0,0,0,0.3);

                h3, p {
                    color: #fff;
                }
            }

            &:not(.premium) {
                @include media-breakpoint-up(md) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: none;
                }

                @include media-breakpoint-down(sm) {
                    margin-bottom: 32px;
                }
            }
        }
    }
}