.ContactModal {

    h2 {

        font-weight: 800;
        margin-left: 1rem;
        margin-bottom: 1rem;
    }

    .modal-content {
        border-radius: 2px;
        border: none;
        box-shadow: 0px 22px 64px rgba(0,0,0,0.3);
    }

    .modal-body {

        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .modal-footer {
        .btn-primary {
            background-color: theme-color("accent");
            border: none;

            &:hover {
                border: none;
                background-color: darken(theme-color("accent"), 2%);
            }

            &:active {
                background-color: darken(theme-color("accent"), 10%);
            }
        }
    }

    div.warning {

        > div {

            @extend .d-flex;

            background-color: theme-color("danger");
            font-size: 0.8rem;
            color: #fff;
            padding: 1rem;

            > i {

                display: block;
                margin-right: 12px;
                font-size: 1.6rem;
            }

            > p {
                margin-bottom: 0;
            }
        }
    }

    .alert {

        border-radius: 0;
        border-radius: 0px;
        border: none;
        margin-bottom: 0;
        padding: 0;

        > div {

            @extend .d-flex;
            padding: 1rem;

            > i {

                display: block;
                margin-right: 12px;
                font-size: 1.6rem;
            }
        }
    }

    form {

        @extend .d-flex, .flex-wrap;
        padding-top: 0;
        padding-bottom: 0;

        .form-group {

            border-top: 1px solid #e9ecef;
            width: 100%;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            overflow: hidden;
            padding-top: 0.1rem;

            &.small {
                width: 50%;
            }

            label {

                font-weight: 600;
                padding-top: 1rem;
                padding-left: 1rem;
                margin-bottom: 0;
                font-size: 0.9rem;
            }

            .form-control {

                border: none;
                padding: 0.5rem 1rem;
                border-radius: 0;
                margin-bottom: 3px;
            }
        }

        .form-group.small + .form-group.small {
            border-left: 1px solid #e9ecef;
        }
    }
}

body {
    transition: filter 0.6s ease-out;
}

body.modal-open {

    > *:not(.modal) {
        filter: blur(4px);
    }
}