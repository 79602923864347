#Header {

    @extend .row, .gradient-primary;

    position: relative;
    margin-bottom: 230px + 100px;
    padding-top: 64px;

    background-image: url('/site/S/img/header_background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    > .bg {

        background-color: rgba(0,0,0,0.4);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    > .col {

        margin-bottom: 230px + 100px;
        margin-top: 100px;
        text-align: center;
    }

    > .canvas {

        width: 100%;
        height: 238px;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;

        >.bg {

            position: absolute;
            width: 764px;
            height: 238px;
            bottom: 0;
            left: 50%;
            margin-left: -382px;
            background-image: url('/site/S/img/header_splash.png');
            background-repeat: no-repeat;
            background-size: contain;

            @include media-breakpoint-only(xs) {
                transform: scale(0.8);
            }

        }
    }

    h1 {

        color: #fff;
        font-weight: 200;
        font-size: 3rem;
        margin-bottom: 1.4rem;
        text-shadow: 0px 0px 20px rgba(0,0,0,1.0);

        @include media-breakpoint-down(lg) {
            font-size: 2.6rem;
        }

        @include media-breakpoint-down(md) {
            font-size: 2.2rem;
        }

        @include media-breakpoint-down(sm) {
            font-size: 1.6rem;
        }

        @include media-breakpoint-only(xs) {
            font-size: 2rem;

            > span {
                width: 100%;
                display: inline-block;
            }
        }

        > span {
            font-weight: 400;
        } 
    }

    > .device {

        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -115px;
        margin-top: -230px;

        > .img {
            filter: drop-shadow(0px 28px 18px rgba(0, 0, 0, 0.14));
        }

        // Animation
        &.wow {

            perspective: 1000px;
            transition-delay: 0.1s;

            .img {

                transform: rotateX(60deg) rotateY(10deg);
                opacity: 0;
                transition: transform 1.2s cubic-bezier(.17,.85,.11,1.2), opacity 1.0s ease-in;
            }

            &.animated {

                .img {

                    transform: translate(0px, 0px) rotateY(0deg);
                    opacity: 1.0;
                }
            }
        }
    }

    &:after {

        @extend .triangles-pattern;

        content: '';
        position: absolute;
        top: 100%;
        height: 240px;
        width: 40%;
        left: 30%;
        z-index: -1;

        @include media-breakpoint-down(sm) {
            width: 80%;
            left: 10%;
        }

        @include media-breakpoint-only(md) {
            width: 70%;
            left: 15%;
        }
    }
}