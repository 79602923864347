.Section {
    
    &.infos {

        h2 {
            width: 100%;
            margin-bottom: 22px;          
        }

        .info {
            @extend .col-md-6;
        }

        h3 {

            font-size: 1rem;
            font-weight: 800;

            @include media-breakpoint-down(sm) {

                cursor: pointer;
                position: relative;
                padding-left: 8px;
                margin-left: 0.5rem;

                &:after {

                    content: '';
                    position: absolute;
                    border-left: 2px solid $gray-800;
                    border-bottom: 2px solid $gray-800;
                    border-radius: 2px;
                    width: 0.5rem;
                    height: 0.5rem;
                    top: 0.25rem;
                    right: 100%;
                    transform: rotate(135deg);
                    transition: transform 0.4s ease-in-out;
                }

                &.collapsed {
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }  
        }

        p {
            text-align: justify;
        }
    }
}