.gradient-primary {
    
    background: rgb(234,47,18);
    background: -moz-linear-gradient(-45deg, rgba(234,47,18,1) 0%, rgba(230,30,0,1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(234,47,18,1) 0%,rgba(230,30,0,1) 100%);
    background: linear-gradient(135deg, rgba(234,47,18,1) 0%,rgba(230,30,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea2f12', endColorstr='#e61e00',GradientType=1 );
}


.gradient-footer {
    background: #444;
    background: linear-gradient(-180deg,  #444 0%,#222 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#555555', endColorstr='#000000',GradientType=1 );

    color: white;
}

.gradient-gray {
    background: rgb(40,40,40);
    background: -moz-linear-gradient(-45deg, rgba(40,40,40,1) 0%, rgba(17,17,17,1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(40,40,40,1) 0%,rgba(17,17,17,1) 100%);
    background: linear-gradient(135deg, rgba(40,40,40,1) 0%,rgba(17,17,17,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#282828', endColorstr='#111111',GradientType=1 );
}

.gradient-light-bottom {
    background-color: white;
    background: -moz-radial-gradient(bottom, ellipse cover, #f9f9f9 0%, #ffffff 50%);
    background: -webkit-gradient(radial, center bottom, 0px, center bottom, 50%, color-stop(0%,#f9f9f9), color-stop(50%,#ffffff));
    background: -webkit-radial-gradient(bottom, ellipse cover, #f9f9f9 0%,#ffffff 50%);
    background: -o-radial-gradient(bottom, ellipse cover, #f9f9f9 0%,#ffffff 50%);
    background: -ms-radial-gradient(bottom, ellipse cover, #f9f9f9 0%,#ffffff 50%);
    background: radial-gradient(ellipse at bottom, #f9f9f9 0%,#ffffff 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#ffffff',GradientType=1 );
}

.gradient-light-top {
    background-color: white;
    background: -moz-radial-gradient(top, ellipse cover, #f9f9f9 0%, #ffffff 50%);
    background: -webkit-gradient(radial, center top, 0px, center top, 50%, color-stop(0%,#f9f9f9), color-stop(50%,#ffffff));
    background: -webkit-radial-gradient(top, ellipse cover, #f9f9f9 0%,#ffffff 50%);
    background: -o-radial-gradient(top, ellipse cover, #f9f9f9 0%,#ffffff 50%);
    background: -ms-radial-gradient(top, ellipse cover, #f9f9f9 0%,#ffffff 50%);
    background: radial-gradient(ellipse at top, #f9f9f9 0%,#ffffff 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#ffffff',GradientType=1 );
}

.triangles-pattern {
    // opacity: 0.6;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23e8e8e8' fill-opacity='0.4'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}