.Section {

    .features {

        @extend .card-deck;

        @include media-breakpoint-only(xs) {
            margin-left: 15px;
            margin-right: 15px;
        }

        > .feature {

            @extend .card;
            margin-top: 15px;
            margin-bottom: 15px;
            border: none;
            box-shadow: 0px 12px 24px rgba(0,0,0,0.06);

            > img {
                @extend .card-img-top;
            }

            > .body {

                @extend .card-body;

                > h5 {

                    @extend .card-title;

                    font-weight: 700;
                }

                > p {
                    @extend .card-text;
                }
            }
        }

        .separator {

            @extend .w-100;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}