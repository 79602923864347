.Section {

    @extend .row;

    h2 {
        position: relative;
        padding-top: 12px;
        font-weight: 800;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 2px;
            width: 60px;
            background-color: theme-color("accent");
        }
    }

    p {
        color: $gray-600;
    }

    &.right {
        @extend .justify-content-end;
    }
}


@import "section/slogan";
@import "section/photo";
@import "section/infos";
@import "section/pro";
@import "section/features";
@import "section/prices";

.Section + .Section {
    margin-top: 22px;
}

.BigSection {

    @extend .col-12;

    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 22px;
    padding-bottom: 22px;

    .overlay {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.83'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        opacity: 0.1;
    }

    h2 {

        color: #fff;
        position: relative;
        z-index: 2;
        font-weight: 800;
        font-size: 2.4rem;
        line-height: 1.7; 
        padding: 2px 0; 
        margin: 120px auto;
        margin-right: 10%;
        text-align: right;

        @include media-breakpoint-up(lg) {
            max-width: 40%;
        }

        @include media-breakpoint-only(xs) {
            margin: 64px auto;
        }

        > span { 

            background-color: rgba(0,0,0,0.6);
            color: #fff; 
            display: inline;
            padding: 0.45rem;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }

        a {
            
            color: #fff;
            background-color: rgba(0,0,0,0.6);
            padding: 0.45rem;

            i {
                margin-right: 12px;
            }
        }

        .facebook-link {
            background-color: #3b5998;
        }

        .twitter-link {
            background-color: #55acee;
        }
    }

    &.small {

        h2 {
            font-size: 2rem;
            margin-top: 64px;
            margin-bottom: 64px;
        }
    }

    &.left {
        
        h2 {
            text-align: left;
            margin-right: auto;
            margin-left: 10%;
        }

    }
}
